import { stripRichTextWrapperTag } from '@/utils/html';
import { getEkomiData } from '~/utils/ekomi';
import { adapter as imageAdapter } from '~/components/cloud-image/utils';
import { highlightV2WrapperTagAdapter } from '~/components/highlight-v2/adapter';

const quickAccessAdapter = (
  heroGenericModuleData,
  { $enhancedLinkSerializer }
) => {
  if (!heroGenericModuleData.active_quick_access) {
    return null;
  }

  const quickAccessLinksTheme = heroGenericModuleData.quick_access_links_theme;

  return {
    title: stripRichTextWrapperTag(heroGenericModuleData.quick_access_title),
    titlePosition:
      heroGenericModuleData.image_position === 'left' ? 'right' : 'left',
    labelShowMore: heroGenericModuleData.quick_access_label_show_more,
    labelShowLess: heroGenericModuleData.quick_access_label_show_less,
    listProducts: heroGenericModuleData.quick_access_links
      .filter(
        ({ quick_access_link, quick_access_link_title }) =>
          quick_access_link && quick_access_link_title
      )
      .map(
        ({
          quick_access_link_icon,
          quick_access_link_title,
          quick_access_link_description,
          quick_access_link,
          quick_access_link_label,
        }) => ({
          image: imageAdapter(quick_access_link_icon),
          title: quick_access_link_title,
          description: quick_access_link_description,
          link: {
            link: $enhancedLinkSerializer(quick_access_link),
            label: quick_access_link_label,
          },
          variant: quickAccessLinksTheme,
        })
      ),
  };
};

export default async ({ primary }, pageData, context) => {
  const { $prismic, $enhancedLinkSerializer } = context;
  const moduleId = primary?.hero_generic_module?.id;

  if (!moduleId) {
    return;
  }

  const heroGenericModule = await $prismic.client.getByID(moduleId);
  const heroGenericModuleData = heroGenericModule?.data;

  let ratings;
  if (heroGenericModuleData.with_ekomi_rating) {
    const ekomiAccount =
      pageData.ekomi_account ?? pageData.market?.data?.ekomi_account;
    const ekomiData = await getEkomiData(ekomiAccount, 'reviews', context);

    ratings = {
      rating: ekomiData?.average_rating,
      variant: 'form',
      showText: false,
    };
  }

  return {
    hasFixedButton: primary.fixed_button,
    type: heroGenericModuleData.type,
    variant: heroGenericModuleData.variant,
    title: stripRichTextWrapperTag(heroGenericModuleData.title),
    surtitle: stripRichTextWrapperTag(heroGenericModuleData.surtitle),
    highlight: highlightV2WrapperTagAdapter(
      heroGenericModuleData.body.find(
        ({ slice_type }) => slice_type === 'highlight_v2'
      )
    ),
    ratings,
    image: imageAdapter(heroGenericModuleData.image),
    imagePosition: heroGenericModuleData.image_position,
    cta: heroGenericModuleData?.cta_label
      ? {
          link: {
            ...$enhancedLinkSerializer(heroGenericModuleData?.cta_link),
            variant: heroGenericModuleData?.cta_variant || 'primary',
          },
          label: heroGenericModuleData?.cta_label,
        }
      : null,
    quickAccess: quickAccessAdapter(heroGenericModuleData, context),
  };
};
